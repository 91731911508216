import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import HomeLanding from "../HeroLanding";
import { gray4, white } from "../styles/Colors";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import NetworkSites from "../brands/NetworkSites";
import HeroTitleOutline from "../HeroOutlineTitle";

const BrandsAmplifyStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "72-amplify-landing.png" }) {
        childImageSharp {
          fluid(maxWidth: 552, maxHeight: 396, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HomeLanding
        noBottom={true}
        section="Amplify"
        image={images.file}
        imageMobile={images.file}
        link={{
          label: "Sign Up",
          attrs: { to: "/contact/", state: { from: "brand" }, as: Link }
        }}
        theme={{
          imageRatio: "69.53%",
          titleWidth: "100%",
          topPadding: "78px",
          topLargePadding: "78px",
          bottomPadding: "160px",
          bottom: white,
          imageTranslate: "translate(calc(100% * 2 / 12), -32%)",
          imageMarginTop: 0,
          bottomPaddingMedia: "86px",
          imageSize: "calc(100% * 6 / 12)"
        }}
      >
        <OutlinedText fill="transparent" component={Headline80} as="span">
          GUARANTEED
        </OutlinedText>
        BRAND <br /> EXPOSURE
      </HomeLanding>

      <NetworkSites />

      <HeroTitleOutline
        theme={{
          background: gray4,
          fill: gray4,
          marginTop: "0",
          paddingTop: "136px",
          paddingTopMobile: "96px",
          paddingBottom: "0"
        }}
        title="WHY"
        subtitle="AMPLIFY?"
      />
    </>
  );
};

export default BrandsAmplifyStatic;
