import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/BrandsAmplifyStatic";

const DeferSection = loadable(() =>
  import("../../components/sections/BrandsAmplifyDefer")
);

const Amplify = () => (
  <Layout>
    <Seo
      title="Amplify Your Brand Exposure"
      description="Sign up to earn more revenue and get guaranteed brand exposure on the web’s top publishers. Includes marketplace listings, editorial, marketing, and more."
    />

    <LazyLoadSection static={<StaticSection />}>
      <DeferSection />
    </LazyLoadSection>
  </Layout>
);

export default Amplify;
