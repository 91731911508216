import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import Container from "../styles/Container";
import { black1, white } from "../styles/Colors";
import { mediaDesktop, mediaMedium } from "../styles/variables";
import { Headline64 } from "../styles/Headings";
import MoveInDiv from "../MoveInDiv";

const Background = styled.div`
  background-color: ${black1};
  padding: 96px 0;
  max-width: 100%;
  overflow: hidden;

  @media (${mediaMedium}) {
    padding: 152px 0 160px;
  }
`;

const Item = styled.div`
  grid-column-end: span 1;

  &:last-child {
    display: none;
  }

  @media (${mediaMedium}) {
    grid-column-end: span 12;
    order: 1 !important;
    width: 85%;

    &:last-child {
      display: block;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  grid-row-gap: 58px;
  grid-column-gap: 18px;
  grid-template-columns: repeat(3, 1fr);

  @media (${mediaMedium}) {
    grid-row-gap: 98px;
    grid-column-gap: 0;
    padding: 0 16px;
    grid-template-columns: repeat(60, 1fr);
  }

  @media (${mediaDesktop}) {
    grid-column-gap: 18px;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }
`;

const Row = styled.div`
  margin-bottom: 96px;

  @media (${mediaMedium}) {
    text-align: center;
    margin-bottom: 120px;
  }
`;

const NetworkSitesSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <Row>
          <Headline64 as="h3" theme={{ color: white }}>
            Get Featured Across our network of 1K+ Media Sites
          </Headline64>
        </Row>
        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item}>
                <MoveInDiv>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </MoveInDiv>
              </Item>
            ))}
        </Grid>
      </Container>
    </Background>
  );
};

const NetworkSites = () => {
  const data = useStaticQuery(graphql`
    query {
      mashable: file(relativePath: { eq: "83-mashable.png" }) {
        childImageSharp {
          fluid(maxWidth: 143, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      tmz: file(relativePath: { eq: "83-tmz.png" }) {
        childImageSharp {
          fluid(maxWidth: 88, maxHeight: 36, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      entrepreneur: file(relativePath: { eq: "83-entrepreneur.png" }) {
        childImageSharp {
          fluid(maxWidth: 179, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      popularScience: file(relativePath: { eq: "83-popular-science.png" }) {
        childImageSharp {
          fluid(maxWidth: 111, maxHeight: 40, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      huffpost: file(relativePath: { eq: "83-huffpost.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, maxHeight: 20, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      goodMorningAmerica: file(
        relativePath: { eq: "83-good-morning-america.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 119, maxHeight: 65, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      newYorkPost: file(relativePath: { eq: "83-new-york-post.png" }) {
        childImageSharp {
          fluid(maxWidth: 179, maxHeight: 27, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      pcMag: file(relativePath: { eq: "83-pc-mag.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, maxHeight: 78, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      engadget: file(relativePath: { eq: "83-engadget.png" }) {
        childImageSharp {
          fluid(maxWidth: 170, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ign: file(relativePath: { eq: "83-ign.png" }) {
        childImageSharp {
          fluid(maxWidth: 122, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <NetworkSitesSection data={data} />;
};

export default NetworkSites;
